import { useStaticQueryWithPreview } from '@tedgustaf/gatsby-plugin-sanity-preview'
import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, PropsWithChildren } from 'react'
import { PageNodeQuery } from '../gatsby/createPages/default'
import { translationsPreviewQueryProjection } from '../preview/staticQueries/translations'
import { Translations } from '../utilities/localization'
import { setGlobalLocale } from '../utilities/time'

export type LocalizationContextProviderProps = {
  currentLanguage: string
}

const LocalizationContext = createContext<Translations>({})

const LocalizationContextProvider = ({
  currentLanguage,
  children,
}: PropsWithChildren<LocalizationContextProviderProps>) => {
  const result = useStaticQueryWithPreview({
    staticQueryData: useStaticQuery<PageNodeQuery<Translations>>(graphql`
      query getTranslations {
        queryResult: allSanityTranslations {
          nodes {
            about
            active
            activeFunds
            addToCalendar
            advisorsDirectory
            advisoryProfessionals
            all
            allNews
            anyBusinessLine
            anyBusinessLine
            anyCountry
            anyFund
            anyOffice
            anySdg
            anySector
            anyStatus
            anyTeam
            anyWorkTitle
            atAGlance
            attachments
            billion
            boardOfDirectors
            born
            businessLine
            businessLines
            calendarHeading
            callButton
            careerJobTitle
            careerTeamFilter
            careerSearchButton
            careerSearchJobPlaceholder
            careerLocationFilter
            cleanEnergy
            cleanWater
            clientRelations
            climateAction
            closeNavigation
            committeeMembers
            company
            companyDirectory
            completed
            completedEventPrefix
            contactCall
            contacts
            contactsAndCalendar
            contactViaEmail
            country
            currentInvestments
            currentPortfolio
            dateOfGrant
            disclaimerAccept
            disclaimerDoNotAccept
            disclaimerNotEligible
            disclaimerSelectCountry
            economicGrowth
            electedDate
            emailLabel
            emailPlaceholder
            employeeSince
            english
            englishAbbr
            entry
            eqtFoundationCommittee
            eqtNewsFilter
            executiveBoard
            exit
            explore
            externalEvent
            from
            fullyinvested
            fund
            fundClassification
            fundManagement
            genderEquality
            generalError
            goodHealth
            industry
            inShort
            institution
            investmentAdvisors
            investmentRange
            investments
            investors
            keyMetrics
            landLife
            language
            languageFilterLabel
            lastUpdated
            latestFrom
            latestNews
            launch
            learnMore
            managementTeam
            million
            more
            moreEvents
            moreNews
            morePeople
            nationality
            netIrr
            news
            newsFilterLabel
            next
            noPoverty
            office
            openNavigation
            optional
            other
            otherAdvisors
            paginationUrlPrefix
            partnerships
            peaceJustice
            peopleDirectory
            photoAttributionPrefix
            placeholderImage
            play
            portfolio
            presentation
            pressReleasesFilter
            previous
            project
            qualityEducation
            realized
            realizedFunds
            realizedGrossMoic
            reCaptchaDisclaimer
            recaptchaMissing
            reducedInequalities
            regulatoryNewsFilter
            report
            reports
            required
            responsibleAdvisor
            responsibleConsumption
            sdg
            searchBarButtonLabel
            searchInputPlaceholder
            searchResults
            searchResultsSubmitButtonLabel
            sector
            seeAll
            seeFullPortfolio
            selectFilters
            selectLanguage
            sendEmailButton
            shareByEmail
            shareholderMeeting
            shareOnLinkedIn
            shareOnTwitter
            size
            slideshowNavigateToItem
            societalContribution
            specialistFunctions
            status
            statusActive
            statusRealized
            subscribeButtonLabel
            subscriptionAlreadySignedUp
            subscriptionFail
            subscriptionSuccess
            sustainableCities
            swedish
            swedishAbbr
            target
            team
            teams
            theme
            toggleSocialMedia
            topic
            turnOffSound
            turnOnSound
            until
            upcoming
            upcomingEventPrefix
            updates
            updatesFilter
            volunteers
            watch
            waterLife
            web
            workTitle
            year
            zeroHunger
          }
        }
      }
    `),
    query: translationsPreviewQueryProjection,
  })

  let translations = result.queryResult.nodes.find(
    translation => translation.language === currentLanguage
  )

  if (!translations) {
    console.warn(
      `Failed to fetch translations for language "${currentLanguage}" from Sanity`
    )
    //Fallback to first translation result
    translations = result.queryResult.nodes.length > 0 ? result.queryResult.nodes[0] : {}
  }

  setGlobalLocale(currentLanguage)

  return (
    <LocalizationContext.Provider value={translations}>
      {children}
    </LocalizationContext.Provider>
  )
}

export { LocalizationContext, LocalizationContextProvider }
